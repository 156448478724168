import React from "react";
import LoginCard from "./components/authentication/loginCard";
import Search from "./components/Search";
import "./css/App.css";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
	const { loginWithRedirect, isAuthenticated } = useAuth0();

	return (
		<div className="App">
			{!isAuthenticated && LoginCard(loginWithRedirect, isAuthenticated)}

			{isAuthenticated && <Search />}
		</div>
	);
}

export default App;
