import React from "react";
import "../../css/search.css";

export default function HeaderLogo() {
	let brandName = "zago";

	return (
		<div className="logo-container">
			<img
				className="logo-header"
				src={`./${brandName}.png`}
				alt={brandName}
			/>
		</div>
	);
}
