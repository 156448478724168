import React from "react";
import "../css/App.css";

export default function FooterSticky(props) {
	return (
		<div>
			<footer className="footer">
				<p>
					<span role="img" aria-label="icon for update">
						🚀
					</span>{" "}
					Date de mise à jour : <strong>{props.date}</strong>
				</p>
			</footer>
		</div>
	);
}
